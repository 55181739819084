























import Vue from 'vue';
import UpdateAvailable from '@/components/UpdateAvailable.vue';

export default Vue.extend({
  name: 'LayoutLogin',
  components: {
    UpdateAvailable,
  },
});
