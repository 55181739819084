


















import Vue from 'vue';
import { state, triggerUpdate } from '@/utils/swHelper';

export default Vue.extend({
  name: 'UpdateAvailable',
  inheritAttrs: false,
  computed: {
    hasUpdate(): boolean {
      return state.hasUpdate;
    },
  },
  methods: {
    triggerUpdate,
  },
});
